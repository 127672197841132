@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100..900&display=swap');
body{
    color: #0E0538;
    font-family: "Archivo", sans-serif;
    font-size: 16px;
}

.bg-gredientBlue{
    @apply bg-gradient-to-b from-[#7258f933] to-white
}

.white_bg_effect::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.46) 30.07%, #FFFFFF 62.61%);

}


/* Custom form filed css */
.custom_design .MuiFormControl-root-MuiTextField-root, .custom_design .MuiFormLabel-root-MuiInputLabel-root{font-family: "Archivo", sans-serif;}
.custom_design .MuiInputBase-root-MuiOutlinedInput-root, .custom_design .MuiInputBase-root-MuiOutlinedInput-root, .custom_design .MuiInputBase-input-MuiOutlinedInput-input{color: #0E0538;border-radius: 16px;}
.custom_design .MuiFormLabel-root-MuiInputLabel-root, .custom_design .MuiFormLabel-root-MuiInputLabel-root.Mui-focused{color: #7258F9;}
/* .custom_design .MuiOutlinedInput-notchedOutline, .custom_design .MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{background-color: transparent;border-color: #0E0538;} */
.custom_design .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline{border-width: 2px;border-radius: 16px;}
.custom_design .MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{border-color: rgb(14, 5, 56);}
.MuiPaper-root-MuiPopover-paper-MuiMenu-paper{border-radius: 18px !important;background-color: #F0EFF6 !important;margin-top: 8px;}
.MuiButtonBase-root-MuiMenuItem-root{color: #191217;}
.MuiButtonBase-root-MuiMenuItem-root.Mui-selected{font-weight: bold;}

.MuiInputBase-root > fieldset  {
    @apply border-2 border-black !important
}

.MuiFormControl-root  > label {
    @apply text-black !important
}

/* otp verify css */
.otp_verify .MuiInputBase-root-MuiOutlinedInput-root{border-radius: 16px;background-color: #F4F2FF;}
.otp_verify .MuiOutlinedInput-notchedOutline{border-color: #F4F2FF;}
.otp_fill_active .MuiOutlinedInput-notchedOutline, .otp_verify .MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{border:2px solid #0E0538;}
.otp_verify .MuiInputBase-input-MuiOutlinedInput-input::placeholder{color: #7258F9;}
.otp_verify .otp_fill_active .MuiInputBase-root-MuiOutlinedInput-root{background-color: transparent;color: #191217;}

/* modal design css */
.customOverlay{background-color: #0e05384d !important;}
.customModal.react-responsive-modal-modal{vertical-align: bottom;margin:0 1.5rem;border-radius: 16px 16px 0 0;padding: 8px;}

